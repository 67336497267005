var userinfoDefault = {
    "success": false,
    "data": {
        "user": {
            "_id": "",
            "username": "",
            "role": "",
            "ts": "",
            "badges": [
                {
                    "id": "",
                    "label": "",
                    "group": null,
                    "ts": ""
                }
            ],
            "xp": 0,
            "gamesplayed": 0,
            "gameswon": 0,
            "gametime": 0,
            "country": "",
            "supporter_tier": 0,
            "verified": false,
            "league": {
                "gamesplayed": 0,
                "gameswon": 0,
                "rating": 0,
                "glicko": 0,
                "rd": 0,
                "rank": "",
                "bestrank": "",
                "apm": 0,
                "pps": 0,
                "vs": 0,
                "decaying": false,
                "standing": 0,
                "percentile": 0,
                "standing_local": 0,
                "prev_rank": "",
                "prev_at": 0,
                "next_rank": "",
                "next_at": 0,
                "percentile_rank": ""
            },
            "avatar_revision": 0,
            "connections": {},
            "friend_count": 0
        }
    },
    "cache": {
        "status": "",
        "cached_at": 0,
        "cached_until": 0
    }
}

export default userinfoDefault;