import React, { useState } from 'react';
import './App.css';
import userinfoDefault from './data/userinfo';
import background from "./src_assets/background.jpg"

function App() {

  var [username, changeName] = useState("");
  var [userinfo, changeInfo] = useState(userinfoDefault);
  var [rankImg, changeRankImg] = useState("");
  var [rankAltImg, changeRankAltImg] = useState("");
  var [profileImg, changeProfileImg] = useState("");
  var [country, changeCountry] = useState("");
  var [containerStatus, changeContainerStatus] = useState([{display: "none"},{display: "none"}]);

  return (

    <div className="App">
      
      <img src={background} alt='background' id="background"></img>

      <h1 id='logo'>TETR.INFO</h1>
      <div id="search">
        
        <input type="text" name="tetrioName" id="name" placeholder="TETR.IO username" onChange={(e) => {
          var NoCapitalName = e.target.value.toLowerCase();
          changeName(NoCapitalName);
          }}></input>
        <button id="searchButton" onClick={() => {

          changeContainerStatus([{display: "none"},{display: "block"}]);

          /* 유저네임 안 쓰면 알려줌 */

          if (username === "") {

            alert("please enter TETR.IO username");

          } else {

            /* 테트리오 채널 api에서 데이터 userinfo state에 저장함 */

            fetch("https://corsproxy.io/?https://ch.tetr.io/api/users/" + username)
            .then((Response) => Response.json())
            .then((data) => {

              /* 유저네임 테트리오에 없으면 알려줌 */

              if (data.success === false) {

                alert("Please enter an existing username");
                changeContainerStatus([{display: "none"},{display: "none"}]);

              } else {

                /* 받아 온 데이터를 화면에 보여줌 */

                console.log(data);
                changeInfo(data);
                changeRankImg("https://tetr.io/res/league-ranks/" + data.data.user.league.rank + ".png");
                changeRankAltImg(data.data.user.league.rank);
                changeProfileImg("https://tetr.io/user-content/avatars/" + data.data.user._id + ".jpg");
                changeCountry("https://tetr.io/res/flags/" + data.data.user.country.toLowerCase() + ".png");
                changeContainerStatus([{display: "block"},{display: "none"}]);

              }
            })
            .catch(() => {

              console.log("An error occurred taking aqi from ch.tetr.io");
              changeContainerStatus([{display: "none"},{display: "none"}]);

            });
          }
        }}>&#128269;</button>

      </div>
      <br></br>
      <div id="loading" style={containerStatus[1]}>

        <p>Loading...</p>

      </div>
      <div id="container" style={containerStatus[0]}>

        <div id="profiles">

          <img src={profileImg} alt="profile" id="profileImg"></img>
          <h1 id="username">{userinfo.data.user.username.toUpperCase()}</h1>
          <img src={country} alt={userinfo.data.user.country} id="flag"></img>

        </div>
        <div id="league">

          <img src={rankImg} id="rankImg" alt={rankAltImg}></img>
          <span id="tr">{Math.round(userinfo.data.user.league.rating)}TR</span>

        </div>
        <div id="badgesContainer">

          <div id="badges">{
            userinfo.data.user.badges.map((value, i) => {
                
              var badgeImg = "https://tetr.io/res/badges/" + value.id + ".png";

              return (
                <div className="badgeContainer" key={i}>
                  
                  <div className="badgeImgContainer">

                    <img src={badgeImg} alt={value.id} className='badgeImg'></img>

                  </div>
                  <div className="badgeLabel"><p className="labelText">{value.label}</p></div>
                  
                </div>
              );

              })
          }</div>
          
        </div>

      </div>

    </div>

  );
}

export default App;
